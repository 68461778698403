import { keyframes } from '@emotion/react';
import { CSSObject } from '@emotion/serialize';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import * as React from 'react';

import { mq } from '@/utility/mediaQuery';
import { defaultTheme } from '@/utility/theme';

interface LoadingProps {
  width?: CSSObject['width'];
  height?: CSSObject['height'];
  color?: string;
  borderWidth?: CSSObject['borderWidth'];
}

const Animation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const StyledLoading = styled.i((props: LoadingProps) =>
  mq({
    '&:after': {
      content: '""',
      display: 'inline-block',
      width: props.width || '16px',
      height: props.height || '16px',
      borderRadius: defaultTheme.border.radius.circle,
      position: 'relative',
      top: '1px',
      left: '4px',
      borderWidth: props.borderWidth || '0.1em',
      borderStyle: 'solid',
      borderColor: rgba(props.color || defaultTheme.color.white, 0.3),
      borderLeftColor: props.color || defaultTheme.color.white,
      animation: `${Animation} 1.1s infinite linear`,
    },
  }),
);

const Loading: React.FC<LoadingProps> = props => {
  return <StyledLoading {...props} />;
};

export default Loading;
