const baseUrl = `${import.meta.env.VITE_API_BASE_URL}/v1`;

// fetch APIはステータスコードによってrejectしてくれないのハンドリングする
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
const handleNetworkError = (res: Response): Response => {
  if (!res.ok) {
    throw new Error(`Network error, status: ${res.status}`);
  }
  return res;
};

const get = (path: string, options?: RequestInit) =>
  fetch(baseUrl + path, {
    ...options,
    method: 'GET',
  }).then(handleNetworkError);

const post = (path: string, options?: RequestInit) =>
  fetch(baseUrl + path, {
    ...options,
    method: 'POST',
  }).then(handleNetworkError);

const put = (path: string, options?: RequestInit) =>
  fetch(baseUrl + path, {
    ...options,
    method: 'PUT',
  }).then(handleNetworkError);

const del = (path: string, options?: RequestInit) =>
  fetch(baseUrl + path, {
    ...options,
    method: 'DELETE',
  }).then(handleNetworkError);

export default {
  get,
  post,
  put,
  delete: del,
};
