/* eslint-disable react/destructuring-assignment */
import { CSSObject } from '@emotion/serialize';
import styled from '@emotion/styled';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import * as React from 'react';

import Loading from '@/components/elements/Icon/Loading';
import { mq } from '@/utility/mediaQuery';
import { defaultTheme } from '@/utility/theme';
import { hoverOpacity } from '@/utility/theme/animation';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  bgColor: keyof typeof defaultTheme.background;
  size:
    | keyof typeof defaultTheme.font.size
    | (keyof typeof defaultTheme.font.size)[];
  radius?: keyof typeof defaultTheme.border.radius;
  fontWeight?: string;
  outline?: boolean;
  width?: CSSObject['width'];
  height?: CSSObject['height'];
  isLoading?: boolean;
  onClick?: () => void;
}

const getColor = (props: ButtonProps): string => {
  if (
    (
      [defaultTheme.color.white, defaultTheme.color.dmmLink] as string[]
    ).includes(defaultTheme.background[props.bgColor])
  ) {
    return defaultTheme.text.default;
  }
  if (props.outline) {
    return defaultTheme.background[props.bgColor];
  }
  return defaultTheme.text.white;
};

const getBgColor = (props: ButtonProps): string => {
  if (props.outline) {
    return defaultTheme.background.white;
  }
  return defaultTheme.background[props.bgColor];
};

const getBorderWidth = (props: ButtonProps): string => {
  if (
    props.fontWeight === 'bold' &&
    (
      [
        defaultTheme.background.primary,
        defaultTheme.background.secondary,
        defaultTheme.background.yellow,
        defaultTheme.background.line,
        defaultTheme.background.twitter,
        defaultTheme.background.x,
      ] as string[]
    ).includes(defaultTheme.background[props.bgColor])
  ) {
    return '2px';
  }
  return '1px';
};

const StyledButton = styled.button((props: ButtonProps) =>
  mq({
    width: props.width ? props.width : '100%',
    height: props.height ? props.height : 'auto',
    color: getColor(props),
    fontSize: Array.isArray(props.size)
      ? props.size.map(s => defaultTheme.font.size[s])
      : defaultTheme.font.size[props.size],
    fontWeight: props.fontWeight,
    textAlign: 'center',
    opacity: props.disabled ? defaultTheme.button.opacity.disabled : 1,
    border: props.outline
      ? `${getBorderWidth(props)} solid ${
          defaultTheme.background[props.bgColor]
        }`
      : 'none',
    borderRadius: props.radius && defaultTheme.border.radius[props.radius],
    backgroundColor: getBgColor(props),
    ...(props.disabled ? {} : hoverOpacity),
  }),
);

const getLoadingColor = (props: ButtonProps) => {
  if (props.outline) {
    return defaultTheme.background[props.bgColor];
  }
  return defaultTheme.background.white;
};

const Text = styled.span(() =>
  mq({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

const SubmitButton: React.FC<ButtonProps> = props => {
  const { children, disabled, isLoading, onClick = () => {} } = props;

  const handleClick = () => {
    if (isLoading || disabled) {
      return;
    }
    onClick();
  };

  return (
    <StyledButton {...props} onClick={() => handleClick()}>
      <Text>
        {children}
        {isLoading && <Loading color={getLoadingColor(props)} />}
      </Text>
    </StyledButton>
  );
};

export default SubmitButton;
