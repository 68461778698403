import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useUserContext } from '@/contexts/user';

const BasicGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { isDisabledMember } = useUserContext();

  return isDisabledMember ? <Navigate to="/disabled" /> : <>{children}</>;
};

export default BasicGuard;
