import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import LogoSvg from '@/assets/icons/logo_dmmkeirin_gray.svg';
import Button from '@/components/elements/Button/BaseButton';
import { TextExternalLink } from '@/components/elements/Text/TextLink';
import Helmet from '@/components/meta/Helmet';
import {
  Container,
  Logo,
  Text,
  TextWrapper,
  Title,
} from '@/components/templates/Error/style';
import { getErrorCode, getLastError } from '@/lib/apollo/error';
import { ErrorCode } from '@/lib/apollo/types/gen/graphql';
import { getLoginUrl } from '@/lib/dmm/accounts';

export interface ErrorTemplateProps {
  apolloError?: ApolloError;
  forceErrorCode?: ErrorCode;
}

const getErrorTitle = (errorCode?: ErrorCode): string => {
  switch (errorCode) {
    case ErrorCode.NotFound:
      return 'ページが見つかりませんでした';
    default:
      return 'エラーが発生しました';
  }
};

const ErrorTemplate: React.FC<ErrorTemplateProps> = ({
  apolloError,
  forceErrorCode,
}) => {
  const lastError = getLastError(apolloError);
  const errorCode: ErrorCode =
    forceErrorCode || getErrorCode(lastError) || ErrorCode.InternalError;
  const statusCode = useMemo(() => {
    switch (errorCode) {
      case ErrorCode.InternalError:
        return 500;
      case ErrorCode.NotFound:
        return 404;
      case ErrorCode.Unauthorized:
        return 401;
      default: {
        return 500;
      }
    }
  }, [errorCode]);

  return (
    <Container>
      <Helmet
        meta={{
          'render:status_code': String(statusCode),
        }}
      >
        <title>{getErrorTitle(errorCode)}</title>
      </Helmet>
      <Logo src={LogoSvg} alt="DMM競輪" />
      <Title>{getErrorTitle(errorCode)}</Title>
      <TextWrapper>
        <Text>
          {errorCode === ErrorCode.InternalError && (
            <>
              ご不便をおかけし申し訳ございません。
              <br />
              時間をおいてアクセスをお試しください。
            </>
          )}
          {errorCode === ErrorCode.NotFound && (
            <>
              お客様がアクセスされたページは、移動もしくは削除された可能性があります。
              <br />
              お手数をおかけしますが、URLをご確認いただくか、TOPページからお探しください。
            </>
          )}
          {errorCode === ErrorCode.Unauthorized && (
            <>再度ログインしてください。</>
          )}
        </Text>
        {errorCode === ErrorCode.InternalError && (
          <Text>
            問題が解決されない場合は、
            <TextExternalLink href={import.meta.env.VITE_DMM_INQUIRY_URL}>
              お問い合わせ
            </TextExternalLink>
            からお知らせください。
          </Text>
        )}
      </TextWrapper>

      {errorCode === ErrorCode.InternalError && (
        <Button
          bgColor="primary"
          size="m"
          radius="s"
          width="184px"
          height="46px"
          onClick={() => window.location.reload()}
        >
          再読み込み
        </Button>
      )}
      {errorCode === ErrorCode.NotFound && (
        <Link to="/">
          <Button
            bgColor="primary"
            size="m"
            radius="s"
            width="184px"
            height="46px"
          >
            DMM競輪 TOPへ
          </Button>
        </Link>
      )}
      {errorCode === ErrorCode.Unauthorized && (
        <Link to={getLoginUrl()}>
          <Button
            bgColor="primary"
            size="m"
            radius="s"
            width="184px"
            height="46px"
          >
            ログイン
          </Button>
        </Link>
      )}
    </Container>
  );
};

export default ErrorTemplate;
