import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useUserContext } from '@/contexts/user';

const NotMemberGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { isMember, isDisabledMember } = useUserContext();

  if (isDisabledMember) {
    return <Navigate to="/" />;
  }

  return !isMember ? <>{children}</> : <Navigate to="/" />;
};

export default NotMemberGuard;
