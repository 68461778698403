import { setContext } from '@apollo/client/link/context';

import { authorize, isNeedReAuthorize } from '@/lib/auth';

let token = '';

export const createAuthLink = () => {
  return setContext(async (_, { headers }) => {
    if (isNeedReAuthorize(token)) {
      token = await authorize();
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  });
};
