import { useMemo } from 'react';
import * as React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

type Meta = {
  [name in string]: string;
};

interface HelmetProps {
  meta?: Meta;
  canonical?: string;
  children?: React.ReactNode;
}

const defaultMeta = {
  description:
    'DMM競輪はレーススケジュールに加え、選手メモや収支管理など投票に役立つ機能が充実。ぜひDMM競輪でインターネット競輪投票をお楽しみください。',
  keywords: '競輪,競輪投票,チャリロト,ケイリン,DMM競輪',
} as Meta;

const replaceContent = (key: string, content: string): Meta => {
  let value = content;
  if (defaultMeta[key]) {
    value = content.replace(/%s/g, defaultMeta[key]);
  }
  return {
    [key]: value,
  };
};

const Helmet: React.FC<HelmetProps> = ({ meta = {}, canonical, children }) => {
  const parseMeta = useMemo(() => {
    const base = { ...defaultMeta };
    Object.keys(meta).forEach(key => {
      Object.assign(base, replaceContent(key, meta[key]));
    });
    return base;
  }, [meta]);

  const title = parseMeta.title
    ? `${parseMeta.title}｜DMM競輪`
    : 'DMM競輪｜スマホで簡単に競輪投票できる！';
  const description = parseMeta.description || defaultMeta.description;
  const url = canonical
    ? `${import.meta.env.VITE_BASE_URL}${canonical}`
    : window.location.href;
  const ogpImagePath =
    parseMeta['og:image'] ||
    `${import.meta.env.VITE_BASE_URL}/images/ogp/default.png`;
  const siteName = 'DMM競輪';

  const twitterMeta: Meta = {
    'twitter:title': title,
    'twitter:description': description,
    'twitter:card': 'summary_large_image',
    'twitter:image': ogpImagePath,
    'twitter:url': url,
    'twitter:site': '@dmm_keirin',
  };

  const ogMeta: Meta = {
    'og:description': description,
    'og:url': url,
    'og:type': 'website',
    'og:title': title,
    'og:site_name': siteName,
    'og:image': ogpImagePath,
    'og:image:secure_url': ogpImagePath,
  };

  const metaData = Object.keys({ ...parseMeta, ...twitterMeta }).map(key => ({
    name: key,
    content: { ...parseMeta, ...twitterMeta }[key],
  }));

  return (
    <ReactHelmet defaultTitle={title} titleTemplate="%s｜DMM競輪">
      <link rel="canonical" href={url} />
      <meta name="robots" content="max-image-preview:large" />
      {metaData.map(_meta => (
        <meta key={_meta.name} name={_meta.name} content={_meta.content} />
      ))}
      {Object.keys(ogMeta).map(key => (
        <meta key={key} property={key} content={ogMeta[key]} />
      ))}
      {children}
    </ReactHelmet>
  );
};

export default Helmet;
