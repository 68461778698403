import styled from '@emotion/styled';

import { mq } from '@/utility/mediaQuery';
import { hoverOpacity } from '@/utility/theme/animation';

export const TextExternalLink = styled.a((props: { fontWeight?: string }) =>
  mq({
    fontWeight: props.fontWeight || 'bold',
    textDecorationLine: 'underline',
    ...hoverOpacity,
  }),
);
