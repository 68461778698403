import { createContext, useContext, useState } from 'react';
import * as React from 'react';

import { LayoutStyles } from '@/layouts/Base/style';

export interface LayoutState extends LayoutStyles {
  setLayoutStyles: (style?: LayoutStyles) => void;
}

const LayoutContext = createContext<LayoutState>({} as LayoutState);

const defaultLayoutStyles: LayoutStyles = {
  customStyles: undefined,
  showBetButton: true,
};

export const LayoutProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<LayoutState>({
    ...defaultLayoutStyles,
  } as LayoutState);

  value.setLayoutStyles = state => {
    setValue(prevState => ({
      ...prevState,
      ...defaultLayoutStyles,
      ...state,
    }));
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
