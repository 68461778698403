export const colorPaletteTheme = {
  primary: '#E60039',
  secondary: '#313331',
  white: '#fff',
  black: '#000',
  grayScale: {
    light: '#f4f5f2',
    mediumLight: '#E9EBE8',
    medium: '#CBCCCA',
    dark: '#838582',
    extraDark: '#6A6B69',
  } as const,
  success: '#00AD79',
  error: '#F53131',
  errorBg: '#FFF2F2',
  money: '#308FBF',
  dmmLink: '#FFCA46',
  twitter: '#1DA1F2',
  x: '#000',
  line: '#06C755',
  blueLink: '#0077CC',
} as const;
