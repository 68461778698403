import { CSSObject } from '@emotion/react';

import { defaultTheme } from '.';
import { buttonTheme } from './button';

export const disclosure = (isOpen: boolean, height: string) => ({
  maxHeight: isOpen ? height : '0',
  overflow: 'hidden',
  visibility: isOpen ? 'unset' : 'hidden',
  transition: '.2s all ease-in-out',
});

export const hoverStyle = (style: CSSObject) => ({
  '@media (hover: hover)': {
    '&:hover': style,
  },
});

export const touchStyle = (style: CSSObject) => ({
  '@media (hover: none)': {
    '&:active': style,
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0.2)',
  },
});

export const hoverOpacity = {
  transition: 'opacity .2s ease-out',
  opacity: 1,
  ...hoverStyle({
    opacity: buttonTheme.opacity.hover,
  }),
  ...touchStyle({
    opacity: buttonTheme.opacity.hover,
  }),
};

export const hoverHighlight = {
  transition: 'background-color .2s ease-out',
  borderRadius: defaultTheme.border.radius.s,
  padding: [
    defaultTheme.spacing.m,
    defaultTheme.spacing.m,
    defaultTheme.spacing.l,
    defaultTheme.spacing.l,
  ],
  ...hoverStyle({
    backgroundColor: defaultTheme.background.mediumLightGray,
  }),
  ...touchStyle({
    opacity: buttonTheme.opacity.hover,
  }),
};
