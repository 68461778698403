// reset.css
import 'destyle.css';

import { ApolloProvider } from '@apollo/client';
import { TrackingTagProvider } from '@dmm-com/react-tracking-tag-api';
import { Global } from '@emotion/react';
import { lazy, Suspense, useEffect, useState } from 'react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import GlobalStyles from '@/components/layouts/GlobalStyles';
import ScrollToTop from '@/components/layouts/ScrollToTop';
import Helmet from '@/components/meta/Helmet';
import ErrorTemplate from '@/components/templates/Error';
import { DmmNavProvider } from '@/contexts/dmmNav';
import { LayoutProvider } from '@/contexts/layout';
import { UserProvider, useUserContext } from '@/contexts/user';
import { createApolloClient } from '@/lib/apollo/client';
import {
  ErrorCode,
  useGetUserQuery,
  useGetUserWalletLazyQuery,
  User,
} from '@/lib/apollo/types/gen/graphql';
import { isLoggedIn } from '@/lib/auth';
import { initDatadog, setDatadogUser } from '@/lib/datadog';
import { getEnvironment } from '@/lib/dmm/trackingTag';
import Routes from '@/routes';
import { defaultTheme } from '@/utility/theme';

import { BreadcrumbProvider } from './contexts/breadcrumb';

const AdTag = lazy(() => import('@/components/meta/AdTag'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { cacheTime: 60 * 60 * 1000, staleTime: Infinity },
  },
});

const InitBeforeInteractive: React.FC = ({ children }) => {
  const [isReady, setIsReady] = useState<boolean>(false);

  // ユーザー取得・Contextにセット
  const userQueryResult = useGetUserQuery({
    errorPolicy: 'ignore',
  });
  useEffect(() => {
    if (userQueryResult.called && !userQueryResult.loading) {
      setIsReady(true);
    }
  }, [userQueryResult.loading]);

  if (!isReady) {
    return <></>;
  }

  return (
    <UserProvider initialUser={(userQueryResult.data?.user || {}) as User}>
      {children}
    </UserProvider>
  );
};

const InitAfterInteractive: React.FC = () => {
  const { user, setUser, isMember } = useUserContext();

  useEffect(() => {
    setDatadogUser(user.userId);
  }, [user.userId]);

  // ユーザーが本会員だった場合、定期的にウォレット更新
  const [walletQuery, walletQueryResult] = useGetUserWalletLazyQuery({
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    pollInterval: 60 * 1000,
  });
  useEffect(() => {
    if (isMember && !walletQueryResult.called) {
      walletQuery();
    }
  }, [user.status, walletQueryResult.called]);

  useEffect(() => {
    if (walletQueryResult.called && !walletQueryResult.loading) {
      if (walletQueryResult.data) {
        setUser(walletQueryResult.data.user as User);
      } else {
        // 「data」がない場合は、再問い合わせを止める
        walletQueryResult.stopPolling();
      }
    }
  }, [
    walletQueryResult.called,
    walletQueryResult.loading,
    walletQueryResult.data,
  ]);

  return (
    <ErrorBoundary fallbackRender={() => <></>}>
      <Suspense fallback={<></>}>
        <AdTag />
      </Suspense>
    </ErrorBoundary>
  );
};

const GlobalErrorFallback: React.FC = () => (
  <>
    <Global
      styles={{
        body: {
          backgroundColor: defaultTheme.background.lightGray,
        },
      }}
    />
    <ErrorTemplate forceErrorCode={ErrorCode.InternalError} />
  </>
);

// 画面のリサイズ時の処理
const setFillHeight = () =>
  document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);

const App: React.FC = () => {
  const client = createApolloClient();

  useEffect(() => {
    initDatadog();
  }, []);

  useEffect(() => {
    setFillHeight();
    window.addEventListener('resize', setFillHeight);
    return () => window.removeEventListener('resize', setFillHeight);
  });

  return (
    <ErrorBoundary fallbackRender={() => <GlobalErrorFallback />}>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <Router>
          <Helmet />
          <ScrollToTop />
          <InitBeforeInteractive>
            <LayoutProvider>
              <DmmNavProvider>
                <QueryClientProvider client={queryClient}>
                  <TrackingTagProvider
                    environment={getEnvironment(import.meta.env.MODE)}
                    params={{
                      device: isMobile ? 'sp' : 'pc',
                      url: `${
                        import.meta.env.VITE_TRACKING_TAG_API_APP_BASE_URL
                      }${window.location.pathname}${window.location.search}`,
                      login: isLoggedIn() ? 1 : 0,
                      options: {
                        i3: '1',
                        gtm: '1',
                        aff: '0',
                      },
                    }}
                    disabled={
                      import.meta.env.VITE_TRACKING_TAG_API_ENABLE === '0'
                    }
                  >
                    <BreadcrumbProvider>
                      <Routes />
                    </BreadcrumbProvider>
                  </TrackingTagProvider>
                </QueryClientProvider>
              </DmmNavProvider>
            </LayoutProvider>
            <InitAfterInteractive />
          </InitBeforeInteractive>
        </Router>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
