import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useUserContext } from '@/contexts/user';

const DisabledMemberGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { isDisabledMember } = useUserContext();

  return isDisabledMember ? <>{children}</> : <Navigate to="/" />;
};

export default DisabledMemberGuard;
