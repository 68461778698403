import { FC, lazy, Suspense } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import { ErrorCode } from '@/lib/apollo/types/gen/graphql';
import { velodromes } from '@/lib/race';
import { NewsType } from '@/pages/News/types';
import BasicGuard from '@/routes/middlewares/BasicGuard';
import NotMemberGuard from '@/routes/middlewares/NotMemberGuard';
import ConversionRoute from '@/routes/SignUp/middlewares/conversionRoute';
import WaitingRoute from '@/routes/SignUp/middlewares/waitingRoute';

import DisabledMemberGuard from './middlewares/DisabledMemberGuard';

// page components
const IndexPage = lazy(() => import('@/pages/Top'));
const CalendarPage = lazy(() => import('@/pages/Schedule/Calendar'));
const GradeRacePage = lazy(() => import('@/pages/Schedule/GradeRace'));
const VelodromesPage = lazy(() => import('@/pages/Velodrome'));
const RaceResultsPage = lazy(() => import('@/pages/Results'));
const RaceCardPage = lazy(() => import('@/pages/RaceCard'));
const CurrentCampaignPage = lazy(() => import('@/pages/CurrentCampaign'));
const ErrorPage = lazy(() => import('@/pages/Error'));
const DisabledMemberPage = lazy(() => import('@/pages/DisabledMember'));
const LpPage = lazy(() => import('@/pages/Lp/Entry'));
const SignUpConversionPage = lazy(() => import('@/pages/SignUp/conversion'));
const SignUpRedirectPage = lazy(() => import('@/pages/SignUp/redirect'));

// nested routes
const BetsRoutes = lazy(() => import('@/routes/Bets'));
const GuideRoutes = lazy(() => import('@/routes/Guide'));
const NewsRoutes = lazy(() => import('@/routes/News'));
const SignUpRoutes = lazy(() => import('@/routes/SignUp'));
const VelodromeRoutes = lazy(() => import('@/routes/Velodrome'));
const WalletRoutes = lazy(() => import('@/routes/Wallet'));
const GlossaryRoutes = lazy(() => import('@/routes/Glossary'));
const MypageRoutes = lazy(() => import('@/routes/Mypage'));

// layouts
const BaseLayout = lazy(() => import('@/layouts/Base'));

const Routes: FC = () => {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route
          path="/lp/entry"
          element={
            <BasicGuard>
              <LpPage />
            </BasicGuard>
          }
        />
        <Route
          path="/signup/conversion"
          element={
            <NotMemberGuard>
              <ConversionRoute Component={SignUpConversionPage} />
            </NotMemberGuard>
          }
        />
        <Route
          path="/signup/redirect"
          element={
            <NotMemberGuard>
              <WaitingRoute Component={SignUpRedirectPage} />
            </NotMemberGuard>
          }
        />

        {/* 共通レイアウト */}
        <Route path="/" element={<BaseLayout />}>
          <Route
            index
            element={
              <BasicGuard>
                <IndexPage />
              </BasicGuard>
            }
          />
          <Route path="/guide/*" element={<GuideRoutes />} />
          <Route path="/signup/*" element={<SignUpRoutes />} />
          {velodromes.map(v => (
            <Route
              key={v.romanName}
              path={`/${v.romanName}/*`}
              element={<VelodromeRoutes velodrome={v} />}
            />
          ))}
          {[NewsType.News, NewsType.Campaign, NewsType.Blog].map(nt => (
            <Route
              key={nt}
              path={`/${nt}/*`}
              element={<NewsRoutes newsType={nt} />}
            />
          ))}
          <Route path="/bets/*" element={<BetsRoutes />} />
          <Route path="/wallet/*" element={<WalletRoutes />} />
          <Route path="/glossary/*" element={<GlossaryRoutes />} />
          <Route path="/my/*" element={<MypageRoutes />} />
          <Route
            path="/racecard"
            element={
              <BasicGuard>
                <RaceCardPage />
              </BasicGuard>
            }
          />
          <Route
            path="/results"
            element={
              <BasicGuard>
                <RaceResultsPage />
              </BasicGuard>
            }
          />
          <Route
            path="/calendar"
            element={
              <BasicGuard>
                <CalendarPage />
              </BasicGuard>
            }
          />
          <Route
            path="/graderace"
            element={
              <BasicGuard>
                <GradeRacePage />
              </BasicGuard>
            }
          />
          <Route
            path="/velodromes"
            element={
              <BasicGuard>
                <VelodromesPage />
              </BasicGuard>
            }
          />
          <Route
            path="/current_campaign"
            element={
              <BasicGuard>
                <CurrentCampaignPage />
              </BasicGuard>
            }
          />

          <Route
            path="/disabled"
            element={
              <DisabledMemberGuard>
                <DisabledMemberPage />
              </DisabledMemberGuard>
            }
          />
        </Route>
        <Route
          path="*"
          element={<ErrorPage forceErrorCode={ErrorCode.NotFound} />}
        />
      </Switch>
    </Suspense>
  );
};

export default Routes;
