/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-param-reassign */
import { NavigateFunction } from 'react-router-dom';

import { isLoggedIn } from '@/lib/auth';
import {
  getCallbackUrl,
  getLoginUrl,
  getLogoutUrl,
  getSignUpUrl,
  loginBaseUrl,
  loginOldUrl,
  logoutBaseUrl,
  signUpBaseUrl,
} from '@/lib/dmm/accounts';

import { DmmNavElement, NavApiResponseObject } from './type';

export const HEADER_ID = 'dmm-nav-header';

// DMM会員登録/ログイン/ログアウト リンクを置換
export const rewriteAccountsUrl = () => {
  const getAnchorElements = (baseUrl: string) => {
    return Array.from(
      document.querySelectorAll(`#${HEADER_ID} a[href^="${baseUrl}"]`),
    ) as HTMLAnchorElement[];
  };

  getAnchorElements(signUpBaseUrl).forEach(anchor => {
    anchor.href = getSignUpUrl();
  });
  getAnchorElements(loginBaseUrl).forEach(anchor => {
    anchor.href = getLoginUrl();
  });
  getAnchorElements(loginOldUrl).forEach(anchor => {
    anchor.href = getLoginUrl();
  });
  getAnchorElements(logoutBaseUrl).forEach(anchor => {
    anchor.href = getLogoutUrl();
  });
};

// ロゴのリンクをSPA挙動に
export const overwriteLogoRouting = (navigate: NavigateFunction): void => {
  const logo = document.querySelector(`#${HEADER_ID} a[data-option="logo"]`);
  if (logo === null) {
    return;
  }
  logo.addEventListener('click', (event: Event) => {
    event.preventDefault();
    navigate('/');
  });
};

// メニューリンクをSPA挙動に
export const overwriteLinkRouting = (navigate: NavigateFunction): void => {
  const linkWrapper = document.querySelector('._n4v1-menu-contents');
  const contentWrapper = document.querySelector('._n4v1-main-contents');
  const links = document.querySelectorAll(
    `#${HEADER_ID} a[data-option="menu"]`,
  );

  links.forEach(l => {
    const href = l.getAttribute('href');
    if (
      href !== null &&
      href.startsWith(import.meta.env.VITE_DMM_NAV_BASE_URL)
    ) {
      const path = href.substring(import.meta.env.VITE_DMM_NAV_BASE_URL.length);
      l.addEventListener('click', (event: Event) => {
        event.preventDefault();
        if (linkWrapper && contentWrapper) {
          // リンクをクリックした時にメニューを閉じ、コンテンツを表示する
          linkWrapper?.classList.remove('_n4v1-show');
          contentWrapper?.classList.remove('_n4v1-hide');
        }
        navigate(path);
      });
    }
  });
};

// CSSの読み込み（レンダリング）
export const loadCSS = (styleRawHTML: string): Promise<void> => {
  const tmpDOM = document.createElement('div');
  tmpDOM.innerHTML = styleRawHTML;

  const promises: Promise<void>[] = [];
  // Helmetだとチラつ気が発生するため、Pure JSでlinkタグを生成する。
  const head = document.getElementsByTagName('head')[0];
  (tmpDOM.childNodes as any as HTMLElement[]).forEach((node: HTMLElement) => {
    if (node.tagName !== 'LINK') {
      return;
    }

    const link = document.createElement('link');
    for (let i = 0; i < node.attributes.length; i += 1) {
      const attr = node.attributes.item(i);
      if (attr === null) {
        continue;
      }
      const value = attr.value !== '' ? attr.value : 'true';
      link.setAttribute(attr.name, value);
    }
    // CSSの読み込みをPromiseで扱う。onload は読み込み完了イベント。
    promises.push(
      new Promise(resolve => {
        link.onload = () => {
          resolve();
        };
      }),
    );
    head.appendChild(link);
  });

  return Promise.all(promises)
    .then(() => Promise.resolve())
    .catch(() => Promise.resolve());
};

// scripts を遅延読み込み（レンダリング）
const SCRIPT_TEMPLATE_ID = 'dmm-nav-head-scripts';
export const lazyLoadScripts = (scriptsRawHTML: string): void => {
  const scriptFragment = document.createDocumentFragment();
  const headTemplate = document.createElement('div');
  headTemplate.innerHTML = scriptsRawHTML;

  // scriptタグ作成
  (headTemplate.childNodes as any as HTMLElement[]).forEach(
    (node: HTMLElement) => {
      if (node.tagName !== 'SCRIPT') {
        return;
      }

      const script = document.createElement('script');
      for (let i = 0; i < node.attributes.length; i += 1) {
        const attr = node.attributes.item(i);
        if (attr === null) {
          continue;
        }
        const value = attr.value !== '' ? attr.value : 'true';
        script.setAttribute(attr.name, value);
      }

      // scriptタグにJSの処理が書いてある場合
      if (node.innerHTML) {
        script.innerHTML = node.innerHTML;
      }

      scriptFragment.appendChild(script);
    },
  );

  // scriptをdivにまとめる
  const scriptTemplate = document.createElement('div');
  scriptTemplate.setAttribute('id', SCRIPT_TEMPLATE_ID);
  scriptTemplate.appendChild(scriptFragment);

  // DOMに古いscriptのdivがあったら削除
  const oldScriptTemplate = document.getElementById(SCRIPT_TEMPLATE_ID);
  if (oldScriptTemplate !== null) {
    oldScriptTemplate.remove();
  }

  // scriptをまとめたdivをbodyの最後尾に挿入
  const body = document.getElementsByTagName('body')[0];
  body.appendChild(scriptTemplate);
};

// NaviAPIから取得
export const fetchNav = (isMobile: boolean): Promise<DmmNavElement> => {
  const url = (() => {
    const baseUrl = isMobile
      ? import.meta.env.VITE_DMM_NAV_API_SP_URL
      : import.meta.env.VITE_DMM_NAV_API_PC_URL;

    const params = new URLSearchParams({
      logo: 'chariloto',
      logo_url: import.meta.env.VITE_BASE_URL,
      is_login: isLoggedIn().toString(),
      login_callback_url: getCallbackUrl(),
    });
    if (isMobile) {
      params.append('headers', 'global,logo,login,notification,menu,coupon');
      params.append('menu_name', 'default');
      params.append('is_login_hide_point', 'true');
    } else {
      params.append('headers', 'global,logo,help,login,notification,coupon');
    }

    return `${baseUrl}?${params.toString()}`;
  })();

  return fetch(url, {
    referrer: import.meta.env.VITE_BASE_URL,
  })
    .then(async res => {
      if (!res.ok) {
        throw new Error(`Network error, status: ${res.status}`);
      }
      const response: NavApiResponseObject = await res.json();
      return {
        footer: response.footer || '',
        head: response.head || '',
        header: response.header || '',
        contentStart: response.main_contents.start || '',
        contentEnd: response.main_contents.end || '',
        scripts: response.scripts || '',
        style: response.style || '',
      };
    })
    .catch((): Promise<DmmNavElement> => {
      return Promise.resolve({
        footer: '',
        head: '',
        header: '',
        contentStart: '',
        contentEnd: '',
        scripts: '',
        style: '',
      });
    });
};
