import { colorPaletteTheme as color } from './color';

export const borderTheme = {
  color: {
    primary: color.primary,
    black: color.secondary,
    extraDarkGray: color.grayScale.extraDark,
    darkGray: color.grayScale.dark,
    mediumGray: color.grayScale.medium,
    mediumLightGray: color.grayScale.mediumLight,
    lightGray: color.grayScale.light,
    white: color.white,
    error: color.error,
  },
  radius: {
    xs: '4px',
    s: '8px',
    m: '10px',
    l: '12px',
    circle: '50%',
  },
} as const;
