import { Model } from '@/lib/microcms/client/types/model';
import {
  Blog as BlogModel,
  Campaign as CampaignModel,
  News as NewsModel,
} from '@/lib/microcms/model';

export enum NewsType {
  News = 'news',
  Campaign = 'campaign',
  Blog = 'blog',
}

export type Content =
  | (NewsModel & Model)
  | (CampaignModel & Model)
  | (BlogModel & Model);
