import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { LoginParams, RawToken, Token } from '@/lib/auth/type';
import { dayjst } from '@/lib/dayjst';
import http from '@/lib/http';

// DMMの login_session_id を取得
export const getDmmLoginSessionId = (): string => {
  return cookie.get('login_session_id') || '';
};

// DMMの dtmu を取得
export const getDmmDtmu = (): string | undefined => cookie.get('dtmu');

// DMMの dtmh を取得
export const getDmmDtmh = (): string | undefined => cookie.get('dtmh');

// DMMのログインCookie所持チェック
export const hasDmmLoginCookie = (): boolean => {
  return getDmmLoginSessionId() !== '';
};

// ログアウトしても消えないDMMのログインCookie所持チェック
export const hasDmmLoginCookieInEvenOnce = (): boolean =>
  getDmmDtmu() !== undefined;

// デコードしたトークンのGetter
export const getRawToken = (token: Token): RawToken | null => {
  try {
    return token ? jwtDecode(token) : null;
  } catch (e) {
    // トークンが不正の場合はnullを返却
    window.console.error(e);
    return null;
  }
};

export const isLoggedIn = (): Boolean => {
  return hasDmmLoginCookie();
};

// ユーザーID(OpenID)を取得
export const getUserId = (token: Token): string => getRawToken(token)!.jti;

export const isTokenExpired = (token: Token): boolean => {
  if (getRawToken(token)) {
    const rowToken = getRawToken(token);
    const now = dayjst().unix();
    return rowToken!.exp < now;
  }
  return false;
};

export const isMatchDmmLoginSessionId = (token: Token): boolean => {
  if (getRawToken(token)) {
    return getDmmLoginSessionId() === getRawToken(token)!.login_session_id;
  }
  return false;
};

// 再認証が必要か判定
export const isNeedReAuthorize = (token: Token): boolean => {
  if (!hasDmmLoginCookie()) {
    return false;
  }
  // 既存トークンが有効なら処理を中断
  return isTokenExpired(token) || !isMatchDmmLoginSessionId(token);
};

export const getLoginParams = (): LoginParams => {
  // DMMログインCookieチェック
  if (!hasDmmLoginCookie()) {
    return {
      login_session_id: '',
    };
  }
  return {
    login_session_id: getDmmLoginSessionId(),
  };
};

export const authorize = (): Promise<Token> => {
  return http
    .post('/auth/dmm', {
      credentials: 'include',
      body: JSON.stringify(getLoginParams()),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async res => {
      const { token }: { token: Token } = await res.json();
      return token;
    })
    .catch(() => {
      // エラー発生時は未認証扱いにする
      return '';
    });
};
