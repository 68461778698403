import { createContext, FC, ReactNode, useContext, useState } from 'react';

export type Breadcrumb = {
  to: string;
  name: string;
};

const BreadcrumbContext = createContext<Breadcrumb[]>([]);
const SetBreadcrumbContext = createContext<(breadcrumbs: Breadcrumb[]) => void>(
  () => {},
);

interface BreadcrumbProviderProps {
  children: ReactNode;
}

export const BreadcrumbProvider: FC<BreadcrumbProviderProps> = ({
  children,
}) => {
  const [breadcrumbs, setter] = useState<Breadcrumb[]>([]);

  const setBreadcrumbs = (_breadcrumbs: Breadcrumb[]) => {
    if (_breadcrumbs.length === 0) {
      setter([]);
      return;
    }
    setter([
      {
        name: 'DMM競輪',
        to: '/',
      },
      ..._breadcrumbs,
    ]);
  };

  return (
    <BreadcrumbContext.Provider value={breadcrumbs}>
      <SetBreadcrumbContext.Provider value={setBreadcrumbs}>
        {children}
      </SetBreadcrumbContext.Provider>
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbContext = () => useContext(BreadcrumbContext);
export const useSetBreadcrumbContext = () => useContext(SetBreadcrumbContext);
