import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useUserContext } from '@/contexts/user';

interface ArgSignupConversionRouteProps {
  Component: FC;
}

const SignUpConversionRoute: FC<ArgSignupConversionRouteProps> = ({
  Component,
}) => {
  const { isLoggedIn } = useUserContext();

  if (!isLoggedIn) {
    return <Navigate to="/signup" />;
  }

  return <Component />;
};

export default SignUpConversionRoute;
