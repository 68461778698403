import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface ScrollTopState {
  cancelScroll?: boolean;
}

// https://reactrouter.com/web/guides/scroll-restoration
export const ScrollToTop = () => {
  const { pathname, state } = useLocation();

  useEffect(() => {
    if ((state as ScrollTopState)?.cancelScroll) {
      return;
    }
    window.scrollTo(0, 0);
  }, [pathname, state]);

  return null;
};

export default ScrollToTop;
