import { rgba } from 'polished';

import { colorPaletteTheme as color } from './color';

export const backgroundTheme = {
  primary: color.primary,
  secondary: color.secondary,
  extraDarkGray: color.grayScale.extraDark,
  darkGray: color.grayScale.dark,
  mediumGray: color.grayScale.medium,
  mediumLightGray: color.grayScale.mediumLight,
  lightGray: color.grayScale.light,
  white: color.white,
  black: color.black,
  overlay: rgba(color.black, 0.5),
  yellow: color.dmmLink,
  error: color.errorBg,
  line: color.line,
  twitter: color.twitter,
  x: color.x,
} as const;
