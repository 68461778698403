import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useUserContext } from '@/contexts/user';

interface ArgSignupWaitingRouteProps {
  Component: FC;
}

const WaitingRoute: FC<ArgSignupWaitingRouteProps> = ({ Component }) => {
  const { isLoggedIn, isWaiting } = useUserContext();

  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');

  if (!isLoggedIn) {
    return <Navigate to="/signup" />;
  }

  if (!code || code !== import.meta.env.VITE_VERIFICATION_CODE) {
    window.location.href = import.meta.env.VITE_VERIFICATION_URL;
    return null;
  }

  return isWaiting ? <Component /> : <Navigate to="/" />;
};

export default WaitingRoute;
