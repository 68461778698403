import dayjs from 'dayjs';
import { createContext, ReactNode, useContext, useState } from 'react';
import * as React from 'react';

import { REFUND_AMOUNT_NEEDS_HOLD } from '@/config/constants/user';
import {
  User,
  UserStatus,
  VerificationStatus,
} from '@/lib/apollo/types/gen/graphql';

interface UserState {
  user: User;
  setUser: (user: User) => void;
  isLoggedIn: boolean;
  isVerified: boolean;
  isWaiting: boolean;
  isSmsWaiting: boolean;
  isMember: boolean;
  hasAgeRestriction: boolean;
  isTemporaryMember: boolean;
  isDisabledMember: boolean;
  isGuest: boolean;
  isViewWalletPage: boolean;
  hasPendingBalance: boolean;
  genericCodeWs: number;
}

const UserContext = createContext<UserState>({} as UserState);

interface UserProviderProps {
  initialUser: User;
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({
  initialUser,
  children,
}) => {
  const [value, setValue] = useState<UserState>({
    user: initialUser,
  } as UserState);

  value.setUser = (newUser: User) => {
    setValue(prev => {
      const newValue = { ...prev };
      newValue.user = { ...prev.user, ...newUser };
      return newValue;
    });
  };

  // ログイン状況
  value.isLoggedIn = value.user.userId !== undefined;
  // 本人確認ステータス
  value.isVerified =
    value.user.verificationStatus === VerificationStatus.Approved;
  value.isWaiting =
    value.user.verificationStatus === VerificationStatus.Waiting;
  value.isSmsWaiting =
    value.user.verificationStatus === VerificationStatus.SmsWaiting;
  value.hasAgeRestriction =
    value.user.verificationStatus === VerificationStatus.AgeRestriction;
  // 競輪会員ステータス
  value.isMember = value.user.status === UserStatus.Regular;
  value.isTemporaryMember = value.user.status === UserStatus.Temporary;
  value.isDisabledMember = value.user.status === UserStatus.Disabled;
  value.isGuest = !value.user.status || value.user.status === UserStatus.Guest;

  // 入金ページの表示制御
  value.isViewWalletPage = false;
  if (value.user.verified) {
    if (
      value.user.verified.isBefore(
        dayjs(import.meta.env.VITE_VIEW_WALLET_BORDER),
      )
    ) {
      // 「本人確認日時 < 指定日付」の場合は表示する
      value.isViewWalletPage = true;
    } else {
      // 「本人確認日時+30日 < 現在日時」の場合は表示する
      value.isViewWalletPage = value.user.verified
        .add(30, 'day')
        .isBefore(dayjs());
    }
  }

  value.hasPendingBalance = value.user.wallet
    ? value.user.wallet.pendingBalance >= REFUND_AMOUNT_NEEDS_HOLD &&
      value.user.address?.addressId === undefined
    : false;

  value.genericCodeWs = 0;
  if (initialUser.wallet) {
    value.genericCodeWs = initialUser.wallet?.genericCodeWs;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
