import { Dayjs } from 'dayjs';

import {
  BetType,
  EntryRacerNote,
  MultiraceProgram,
  MultiraceRandomTickets,
  ValidFlag,
} from '@/lib/apollo/types/gen/graphql';

// Enums
export enum RaceInfoType {
  Score = 'score', // 競走得点
  Basic = 'basic', // 出走表
  RecentResult = 'recentResult', // 直近成績
  Result = 'result', // 結果
  Odds = 'odds', // オッズ
  Order = 'order', // 購入情報
  None = 'none', // 指定なし(枠番・車番・選手名のみ)
  VelodromeResult = 'velodromeResult', //該当競輪場成績
}

export enum RaceType {
  Singlerace = 'race', // 7掛式
  Multirace = 'lottery', // 重勝
}

export enum LotteryType {
  Chariloto = 'chariloto',
  Dokanto = 'dokanto',
}

export enum BetMethod {
  Formation = 'formation', // フォーメーション
  Box = 'box', // ボックス
  Wheel = 'wheel', // 流し
  Odds = 'odds', // オッズ
}

export enum OrderStep {
  Ready = 'ready',
  Confirm = 'confirm',
  Complete = 'complete',
  OtherCharge = 'other_charge',
}

export enum WheelAxis {
  First = 'first', // 1着軸
  Second = 'second', // 2着軸
  Third = 'third', // 3着軸
  FirstSecond = 'firstSecond', // 1-2着軸
  FirstThird = 'firstThird', // 1-3着軸
  SecondThird = 'secondThird', // 2-3着軸
  One = 'one', // 1軸
  Two = 'two', // 2軸
}

export enum OddsListType {
  Table = 'table', // 一覧
  Rank = 'rank', // 人気順+高配当順
  Popularity = 'popularity', // 人気順
  Dividend = 'dividend', // 高配当順
}

export enum WalletPayoutType {
  Bank = 'bank',
  Toreta = 'toreta',
}

export enum ChargeType {
  Credit = 'credit',
  Wallet = 'wallet',
  Paypay = 'paypay',
}

// Types
export type Velodrome = {
  code: number;
  name: string;
  romanName: string;
  prefecture: string;
  area: Area;
  group?: Group;
  isClosed?: boolean;
  isHideHistoryOption?: boolean;
};

export enum Area {
  Kitanihon = 'kitanihon', // 北日本
  Kanto = 'kanto', // 関東
  Minamikanto = 'minamikanto', // 南関東
  Chubu = 'chubu', // 中部
  Kinki = 'kinki', // 近畿
  Chugoku = 'chugoku', // 中国
  Shikoku = 'shikoku', // 四国
  Kyushu = 'kyushu', // 九州
}

export enum Group {
  A = 'A',
  B = 'B',
  C = 'C',
  E = 'E',
  G = 'G',
}

export enum DebitType {
  Paypay = 'paypay',
  Gmo = 'gmo',
}

export type OddsDetail = {
  odds?: number;
  oddsOne?: number;
  oddsTwo?: number;
  setNum: number;
  rank: number;
  validFlag: ValidFlag;
};

export type OddsInfo = {
  detail: OddsDetail[];
  releaseBetCount: number;
  returnBetCount: number;
  salesBetCount: number;
};

export type Odds = {
  [key in BetType]: OddsInfo;
};

export type OddsData = {
  data: Odds;
  exists: boolean;
};

export type Ticket = {
  setNum: number;
  count: number;
  isRemoved: boolean;
};

export type WheelAxisList = { [key in BetType]: WheelAxis };

export type WheelRacerData = {
  [key1 in BetType]: {
    [key2 in WheelAxis]: FirstThreeRacerList;
  };
};

export type FirstThreeRacerList = [RacerNumber[], RacerNumber[], RacerNumber[]];

export type FirstThreeRacerData = {
  [BetMethod.Formation]: FirstThreeRacerList;
  [BetMethod.Box]: FirstThreeRacerList;
  [BetMethod.Wheel]: WheelRacerData;
};

export type BetDetail = {
  isMulti: boolean;
  betMethod: BetMethod;
  betType: BetType;
  tickets: Ticket[];
  firstThreeRacerList: FirstThreeRacerList;
  wheelAxis?: WheelAxis;
  orderDeadline: Dayjs;
};

export type BetData = {
  [uuid: string]: BetDetail;
};

export type BetDataList = {
  [raceId: string]: BetData;
};

export type RacerNumber = {
  bicycleNum: number;
  bracketNum: number;
};

export type BettableItem = {
  setNum: number;
  isRemoved: boolean;
};

export type BettableList = {
  [key in BetType]: BettableItem[];
};

export type BettableData = {
  [key in BetMethod]: BettableList;
};

export type BetCountList = {
  [key2 in BetType]: number;
};

export type BetCountData = {
  [key1 in BetMethod]: BetCountList;
};

export type IsMultiData = {
  [BetMethod.Formation]: boolean;
  [BetMethod.Wheel]: {
    [key in BetType]: boolean;
  };
};

export type BetTypeList = {
  [BetMethod.Formation]: BetType[];
  [BetMethod.Box]: BetType[];
  [BetMethod.Wheel]: BetType;
  [BetMethod.Odds]: BetType;
};

export type ScoreType = {
  heldDate: string;
  velodromeCode: string;
  rank: Number;
  raceNum: Number;
  raceEventName: string;
  personalStatus: string;
};

export enum ScoreCsvIndex {
  HeldDate,
  VelodromeCode,
  Rank,
  RaceNum,
  RaceEventName,
  PersonalStatus,
}

export type FirstPlaceList = {
  [raceNum: number]: number[];
};

export type MultiraceRandomBetDataListByUser = {
  [userId: string]: MultiraceRandomBetDataList;
};

export type MultiraceRandomBetDataList = {
  [multiraceProgramId: number]: {
    tickets: MultiraceRandomTickets[];
    salesDeadline: Dayjs;
  };
};

export type MultiraceSelectBetDetail = {
  firstPlaceList: FirstPlaceList;
  tickets: number[][];
  isRemoved: boolean;
  salesDeadline: Dayjs;
};

export type MultiraceSelectBetData = {
  [uuid: string]: MultiraceSelectBetDetail;
};

export type MultiraceSelectBetDataList = {
  [multiraceProgramId: number]: MultiraceSelectBetData;
};

export type CurrentMultiraceProgramMap = {
  [velodromeCode: number]: MultiraceProgram[];
};

export type EntryRacerNoteData = {
  name: string;
  registrationNum: number;
  entryRacerNotes: EntryRacerNote[];
};
