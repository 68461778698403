const defaultFontFamily = `"Segoe UI", "Helvetica Neue", Helvetica, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", Arial, "メイリオ", Meiryo, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans Emoji"`;

export const fontTheme = {
  size: {
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '18px',
    xl: '20px',
    xxl: '24px',
  },
  height: {
    base: 1,
    s: 1.1,
    m: 1.4,
    l: 1.6,
  },
  family: {
    default: defaultFontFamily,
    bicycleNumber: `"Manrope", ${defaultFontFamily}`,
  },
} as const;
