import cookie from 'js-cookie';

const INVITER_COOKIE_KEY = 'inviter';

// 招待者のUserIdをCookieから取得
export const getInviterCookie = (): string | undefined => {
  return cookie.get(INVITER_COOKIE_KEY) || undefined;
};

// 招待者のUserIdをCookieにセット
export const setInviterCookie = (userId: string) =>
  cookie.set(INVITER_COOKIE_KEY, userId, {
    path: '/',
    expires: 7,
  });
