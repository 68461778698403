import styled from '@emotion/styled';

import { mq } from '@/utility/mediaQuery';
import { defaultTheme } from '@/utility/theme';

export const Container = styled.div(() =>
  mq({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 24px',
    marginTop: ['72px', '72px', '120px'],
  }),
);

export const Logo = styled.img(() =>
  mq({
    height: ['32px', '32px', '40px'],
    marginBottom: defaultTheme.spacing.xxl,
  }),
);

export const Title = styled.h1(() =>
  mq({
    fontSize: defaultTheme.font.size.l,
    color: defaultTheme.color.grayScale.dark,
    fontWeight: 'bold',
    marginBottom: defaultTheme.spacing.l,
  }),
);

export const TextWrapper = styled.div(() =>
  mq({
    marginBottom: '40px',
    lineHeight: defaultTheme.font.height.l,
    textAlign: 'center',
  }),
);

export const Text = styled.p(() =>
  mq({
    fontSize: defaultTheme.font.size.m,
    color: defaultTheme.color.grayScale.dark,
  }),
);
