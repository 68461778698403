import { withScalars } from 'apollo-link-scalars';
import dayjs from 'dayjs';
import { buildClientSchema } from 'graphql';

import schemaJson from '@/lib/apollo/links/customScalar/gen/graphql.schema.json';
import { dayjst } from '@/lib/dayjst';

export const customScalarLink = withScalars({
  schema: buildClientSchema(schemaJson as any),
  typesMap: {
    Date: {
      serialize: (parsed: dayjs.Dayjs) => parsed.format('YYYY-MM-DD'),
      parseValue: (raw: string) => {
        return raw ? dayjst(raw) : null;
      },
    },
    Time: {
      serialize: (parsed: dayjs.Dayjs) => parsed.format(),
      parseValue: (raw: string) => {
        return raw ? dayjst(raw) : null;
      },
    },
  },
});
