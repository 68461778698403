import '@fontsource/manrope/400.css';
import '@fontsource/manrope/700.css';

import { Global } from '@emotion/react';
import * as React from 'react';
import { isIOS } from 'react-device-detect';

import { defaultTheme } from '@/utility/theme';

export const duration = 200;

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={{
        html: {
          overflow: 'auto',
          touchAction: 'manipulation',
          '&.disable-scroll, &.disable-scroll > body': {
            height: isIOS ? 'calc(var(--vh) - 1px)' : 'auto',
            overflow: isIOS ? 'hidden' : 'unset',
          },
          '&.disable-scroll > body': {
            position: isIOS ? 'unset' : 'fixed',
          },
        },
        body: {
          fontFamily: defaultTheme.font.family.default,
          backgroundColor: defaultTheme.background.lightGray,
          minWidth: '320px',
        },
        p: {
          lineHeight: defaultTheme.font.height.l,
        },
        '*:focus': {
          outline: 'none',
        },
        '.enter': {
          opacity: 0,
        },
        '.enter-active': {
          opacity: 1,
          transition: `opacity ${duration}ms ease-in-out`,
        },
        '.exit': {
          opacity: 1,
        },
        '.exit-active': {
          opacity: 0,
          transition: `opacity ${duration}ms ease-in-out`,
        },
      }}
    />
  );
};

export default GlobalStyles;
