import 'dayjs/locale/ja';

import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';

/**
 * JST対応
 * Doc: https://github.com/iamkun/dayjs
 * See: https://day.js.org/docs/en/manipulate/utc-offset
 */
dayjs.extend(utc);

dayjs.extend(isBetween);

export const daysInMonth = (year: number, month: number) => {
  // Dateの月は0から数えるfxxk仕様なので-1する
  const date = new Date(year, month - 1);
  return dayjs(date).daysInMonth();
};

export const diffFromTargetDate = (
  year: number,
  month: number,
  date: number,
  unit: QUnitType | OpUnitType,
) => {
  const targetDate = new Date(year, month - 1, date);
  return dayjs().diff(targetDate, unit);
};

export const dayjst = (
  date?: string | number | Date | dayjs.Dayjs,
): dayjs.Dayjs => {
  return dayjs(date)
    .utcOffset(9 * 60)
    .locale('ja');
};
