import { Area, Group, Velodrome } from '@/modelTypes';

export enum LiveSiteName {
  YouTube = 'YouTube',
  NicoNama = 'ニコニコ生放送',
  Official = '競輪場公式',
  KeirinJP = 'KEIRIN.jp',
}

export const velodromes: Velodrome[] = [
  {
    code: 11,
    name: '函館',
    romanName: 'hakodate',
    area: Area.Kitanihon,
    prefecture: '北海道',
  },
  {
    code: 12,
    name: '青森',
    romanName: 'aomori',
    area: Area.Kitanihon,
    prefecture: '青森県',
  },
  {
    code: 13,
    name: 'いわき平',
    romanName: 'iwakitaira',
    area: Area.Kitanihon,
    prefecture: '福島県',
    group: Group.B,
  },
  {
    code: 21,
    name: '弥彦',
    romanName: 'yahiko',
    area: Area.Kanto,
    prefecture: '新潟県',
  },
  {
    code: 22,
    name: '前橋',
    romanName: 'maebashi',
    area: Area.Kanto,
    prefecture: '群馬県',
  },
  {
    code: 23,
    name: '取手',
    romanName: 'toride',
    area: Area.Kanto,
    prefecture: '茨城県',
  },
  {
    code: 24,
    name: '宇都宮',
    romanName: 'utsunomiya',
    area: Area.Kanto,
    prefecture: '栃木県',
  },
  {
    code: 25,
    name: '大宮',
    romanName: 'omiya',
    area: Area.Kanto,
    prefecture: '埼玉県',
  },
  {
    code: 26,
    name: '西武園',
    romanName: 'seibuen',
    area: Area.Kanto,
    prefecture: '埼玉県',
  },
  {
    code: 27,
    name: '京王閣',
    romanName: 'keiokaku',
    area: Area.Kanto,
    prefecture: '東京都',
  },
  {
    code: 28,
    name: '立川',
    romanName: 'tachikawa',
    area: Area.Kanto,
    prefecture: '東京都',
  },
  {
    code: 31,
    name: '松戸',
    romanName: 'matsudo',
    area: Area.Minamikanto,
    prefecture: '千葉県',
    group: Group.E,
  },
  {
    code: 32,
    name: '千葉',
    romanName: 'chiba',
    area: Area.Minamikanto,
    prefecture: '千葉県',
    group: Group.B,
  },
  {
    // 2010/3/31 廃止
    code: 33,
    name: '花月園',
    romanName: 'kagetsuen',
    area: Area.Minamikanto,
    prefecture: '神奈川県',
    isClosed: true,
    isHideHistoryOption: true,
  },
  {
    code: 34,
    name: '川崎',
    romanName: 'kawasaki',
    area: Area.Minamikanto,
    prefecture: '神奈川県',
    group: Group.A,
  },
  {
    code: 35,
    name: '平塚',
    romanName: 'hiratsuka',
    area: Area.Minamikanto,
    prefecture: '神奈川県',
    group: Group.A,
  },
  {
    code: 36,
    name: '小田原',
    romanName: 'odawara',
    area: Area.Minamikanto,
    prefecture: '神奈川県',
    group: Group.A,
  },
  {
    code: 37,
    name: '伊東',
    romanName: 'ito',
    area: Area.Minamikanto,
    prefecture: '静岡県',
    group: Group.B,
  },
  {
    code: 38,
    name: '静岡',
    romanName: 'shizuoka',
    area: Area.Minamikanto,
    prefecture: '静岡県',
    group: Group.B,
  },
  {
    // 2014/03/16 廃止
    code: 41,
    name: '一宮',
    romanName: 'ichinomiya',
    area: Area.Chubu,
    prefecture: '愛知県',
    isClosed: true,
    isHideHistoryOption: true,
  },
  {
    code: 42,
    name: '名古屋',
    romanName: 'nagoya',
    area: Area.Chubu,
    prefecture: '愛知県',
  },
  {
    code: 43,
    name: '岐阜',
    romanName: 'gifu',
    area: Area.Chubu,
    prefecture: '岐阜県',
  },
  {
    code: 44,
    name: '大垣',
    romanName: 'ogaki',
    area: Area.Chubu,
    prefecture: '岐阜県',
  },
  {
    code: 45,
    name: '豊橋',
    romanName: 'toyohashi',
    area: Area.Chubu,
    prefecture: '愛知県',
    group: Group.G,
  },
  {
    code: 46,
    name: '富山',
    romanName: 'toyama',
    area: Area.Chubu,
    prefecture: '富山県',
  },
  {
    code: 47,
    name: '松阪',
    romanName: 'matsusaka',
    area: Area.Chubu,
    prefecture: '三重県',
    group: Group.G,
  },
  {
    code: 48,
    name: '四日市',
    romanName: 'yokkaichi',
    area: Area.Chubu,
    prefecture: '三重県',
  },
  {
    code: 51,
    name: '福井',
    romanName: 'fukui',
    area: Area.Kinki,
    prefecture: '福井県',
  },
  {
    // 2011/3/11 廃止
    code: 52,
    name: '大津',
    romanName: 'otsu',
    area: Area.Kinki,
    prefecture: '滋賀県',
    isClosed: true,
    isHideHistoryOption: true,
  },
  {
    code: 53,
    name: '奈良',
    romanName: 'nara',
    area: Area.Kinki,
    prefecture: '奈良県',
    group: Group.C,
  },
  {
    code: 54,
    name: '向日町',
    romanName: 'mukomachi',
    area: Area.Kinki,
    prefecture: '京都府',
    group: Group.B,
  },
  {
    code: 55,
    name: '和歌山',
    romanName: 'wakayama',
    area: Area.Kinki,
    prefecture: '和歌山県',
  },
  {
    code: 56,
    name: '岸和田',
    romanName: 'kishiwada',
    area: Area.Kinki,
    prefecture: '大阪府',
  },
  {
    code: 61,
    name: '玉野',
    romanName: 'tamano',
    area: Area.Chugoku,
    prefecture: '岡山県',
    group: Group.C,
  },
  {
    code: 62,
    name: '広島',
    romanName: 'hiroshima',
    area: Area.Chugoku,
    prefecture: '広島県',
    group: Group.C,
  },
  {
    code: 63,
    name: '防府',
    romanName: 'hofu',
    prefecture: '山口県',
    area: Area.Chugoku,
  },
  {
    code: 71,
    name: '高松',
    romanName: 'takamatsu',
    area: Area.Shikoku,
    prefecture: '香川県',
    group: Group.C,
  },
  {
    // 2012/3/7 廃止
    code: 72,
    name: '観音寺',
    romanName: 'kanonji',
    area: Area.Shikoku,
    prefecture: '香川県',
    isClosed: true,
    isHideHistoryOption: true,
  },
  {
    code: 73,
    name: '小松島',
    romanName: 'komatsushima',
    area: Area.Shikoku,
    prefecture: '徳島県',
    group: Group.B,
  },
  {
    code: 74,
    name: '高知',
    romanName: 'kochi',
    area: Area.Shikoku,
    prefecture: '高知県',
    group: Group.C,
  },
  {
    code: 75,
    name: '松山',
    romanName: 'matsuyama',
    area: Area.Shikoku,
    prefecture: '愛媛県',
    group: Group.A,
  },
  {
    code: 81,
    name: '小倉',
    romanName: 'kokura',
    area: Area.Kyushu,
    prefecture: '福岡県',
    group: Group.G,
  },
  {
    code: 83,
    name: '久留米',
    romanName: 'kurume',
    area: Area.Kyushu,
    prefecture: '福岡県',
  },
  {
    code: 84,
    name: '武雄',
    romanName: 'takeo',
    area: Area.Kyushu,
    prefecture: '佐賀県',
  },
  {
    code: 85,
    name: '佐世保',
    romanName: 'sasebo',
    area: Area.Kyushu,
    prefecture: '長崎県',
  },
  {
    code: 86,
    name: '別府',
    romanName: 'beppu',
    area: Area.Kyushu,
    prefecture: '大分県',
    group: Group.G,
  },
  {
    code: 87,
    name: '熊本',
    romanName: 'kumamoto',
    area: Area.Kyushu,
    prefecture: '熊本県',
    group: Group.G,
  },
];

export const areas: { [key in Area]: string } = {
  [Area.Kitanihon]: '北日本',
  [Area.Kanto]: '関東',
  [Area.Minamikanto]: '南関東',
  [Area.Chubu]: '中部',
  [Area.Kinki]: '近畿',
  [Area.Chugoku]: '中国',
  [Area.Shikoku]: '四国',
  [Area.Kyushu]: '九州',
};

export const velodromeCodeMap: {
  [key: number]: Velodrome;
} = {};
export const velodromeNameMap: {
  [key: string]: Velodrome;
} = {};
export const velodromeRomanNameMap: {
  [key: string]: Velodrome;
} = {};
export const velodromeAreasMap: {
  [key in Area]: Velodrome[];
} = {
  [Area.Kitanihon]: [],
  [Area.Kanto]: [],
  [Area.Minamikanto]: [],
  [Area.Chubu]: [],
  [Area.Kinki]: [],
  [Area.Chugoku]: [],
  [Area.Shikoku]: [],
  [Area.Kyushu]: [],
};

export const velodromeGroupMap: {
  [key in Group]: Velodrome[];
} = {
  [Group.A]: [],
  [Group.B]: [],
  [Group.C]: [],
  [Group.E]: [],
  [Group.G]: [],
};

velodromes.forEach(v => {
  velodromeCodeMap[v.code] = v;
  velodromeNameMap[v.name] = v;
  velodromeRomanNameMap[v.romanName] = v;
  if (!v.isClosed) {
    velodromeAreasMap[v.area].push(v);
  }
  if (v.group) {
    velodromeGroupMap[v.group].push(v);
  }
});

export const getVelodromeByCode = (code: number) => {
  return velodromeCodeMap[code];
};

export const getVelodromeByName = (name: string) => {
  return velodromeNameMap[name];
};

export const getVelodromeByRomanName = (romanName: string) => {
  return velodromeRomanNameMap[romanName];
};
