import { backgroundTheme } from './background';
import { borderTheme } from './border';
import { buttonTheme } from './button';
import { colorPaletteTheme as colorTheme } from './color';
import { fontTheme } from './font';
import { formTheme } from './form';
import { shadowTheme } from './shadow';
import { spacingTheme } from './spacing';
import { textTheme } from './text';

export const defaultTheme = {
  button: buttonTheme,
  text: textTheme,
  form: formTheme,
  border: borderTheme,
  background: backgroundTheme,
  shadow: shadowTheme,
  font: fontTheme,
  spacing: spacingTheme,
  color: colorTheme,
} as const;

export type DefaultThemeType = typeof defaultTheme;
