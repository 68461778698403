import { colorPaletteTheme as color } from './color';
import { fontTheme } from './font';

export const buttonTheme = {
  font: fontTheme,
  opacity: {
    hover: 0.6,
    disabled: 0.3,
  },
  backgroundColor: {
    primary: color.primary,
    secondary: color.secondary,
  },
} as const;
