import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { typePolicies } from '@/lib/apollo/cache/typePolicies';
import { customScalarLink } from '@/lib/apollo/links/customScalar';
import { httpLink } from '@/lib/apollo/links/http';

import { createAuthLink } from '../links/auth';

export const createApolloClient = () => {
  const authLink = createAuthLink();

  return new ApolloClient({
    link: ApolloLink.from([authLink, customScalarLink, httpLink]),
    cache: new InMemoryCache({ typePolicies }),
    ssrMode: false,
    connectToDevTools: import.meta.env.VITE_ENABLE_APOLLO_TOOLS === '1',
  });
};
