import { TypedTypePolicies } from '@/lib/apollo/cache/gen/helpers';

export const typePolicies: TypedTypePolicies = {
  User: {
    keyFields: ['userId'],
  },
  BankAccount: {
    keyFields: ['accountHolder'],
  },
};
