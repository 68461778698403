import { UnitType } from 'dayjs';

import { AnalyzeType } from '@/components/templates/Bet/Analyze/types';

import { BetResultRangeType } from '../apollo/types/gen/graphql';

export const timeFilters = [
  // TODO: 2022年になったら通算を表示するようにする
  // {
  //   name: '通算',
  //   analyzeType: AnalyzeType.Total,
  // },
  {
    name: '年間',
    analyzeType: AnalyzeType.Annually,
  },
  {
    name: '月間',
    analyzeType: AnalyzeType.Monthly,
  },
  {
    name: '当月',
    analyzeType: AnalyzeType.Month,
  },
];

export const getUnit = (type: BetResultRangeType): UnitType => {
  switch (type) {
    case BetResultRangeType.Total: {
      return 'years';
    }
    case BetResultRangeType.Annually: {
      return 'year';
    }
    case BetResultRangeType.Monthly: {
      return 'month';
    }
    default: {
      throw new Error('未定義');
    }
  }
};

export const getAnalyzeTypeName = (
  analyzeType?: AnalyzeType,
):
  | '通算'
  | '年間'
  | '月間'
  | '当月'
  | '競輪場別'
  | '開催時間別'
  | 'グレード別'
  | '' => {
  switch (analyzeType) {
    case AnalyzeType.Month: {
      return '当月';
    }
    case AnalyzeType.Monthly: {
      return '月間';
    }
    case AnalyzeType.Annually: {
      return '年間';
    }
    case AnalyzeType.Total: {
      return '通算';
    }
    case AnalyzeType.Velodrome: {
      return '競輪場別';
    }
    case AnalyzeType.TimeFrame: {
      return '開催時間別';
    }
    case AnalyzeType.GradeName: {
      return 'グレード別';
    }
    default: {
      return '';
    }
  }
};

export const getRangeType = (analyzeType?: AnalyzeType): BetResultRangeType => {
  switch (analyzeType) {
    case AnalyzeType.Month:
    case AnalyzeType.Monthly: {
      return BetResultRangeType.Monthly;
    }
    case AnalyzeType.Annually: {
      return BetResultRangeType.Annually;
    }
    case AnalyzeType.Total: {
      return BetResultRangeType.Total;
    }
    default: {
      return BetResultRangeType.Monthly;
    }
  }
};
