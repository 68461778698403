import { getInviterCookie } from '@/lib/invitation';

const accountsBaseUrl = import.meta.env.VITE_DMM_ACCOUNTS_URL;
const baseUrl = import.meta.env.VITE_BASE_URL;

export const getCallbackUrl = (path?: string) =>
  encodeURIComponent(path ? `${baseUrl}${path}` : window.location.href);

export const signUpBaseUrl = `${accountsBaseUrl}/welcome/signup/email/=/`;
export const getSignUpUrl = (backPath: string = '/signup') => {
  const inviter = getInviterCookie();
  return `${signUpBaseUrl}back_url=${getCallbackUrl(
    `${backPath}${inviter ? `?inviter=${inviter}` : ''}`,
  )}`;
};

export const loginOldUrl = `${accountsBaseUrl}/service/login/password/=/`;
export const loginBaseUrl = `${accountsBaseUrl}/service/login/password/=/channel=keirin/`;
export const getLoginUrl = (backPath?: string) => {
  return `${loginBaseUrl}path=${getCallbackUrl(backPath)}`;
};

export const logoutBaseUrl = `${accountsBaseUrl}/service/logout/=/`;
export const getLogoutUrl = () => {
  return `${logoutBaseUrl}path=${encodeURIComponent(baseUrl)}`;
};

export const settingsBaseUrl = `${accountsBaseUrl}/settings`;

export const settingsProfileBaseUrl = `${accountsBaseUrl}/settings/profile`;
