import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { ErrorCode } from '@/lib/apollo/types/gen/graphql';

export const getLastError = (error?: ApolloError): GraphQLError | undefined => {
  return error
    ? error.graphQLErrors[error.graphQLErrors.length - 1]
    : undefined;
};

export const getErrorCode = (
  graphQLError?: GraphQLError,
): ErrorCode | undefined => {
  if (graphQLError?.extensions?.code) {
    if (graphQLError.extensions.code in ErrorCode) {
      return graphQLError.extensions.code as ErrorCode;
    }
  }
  return undefined;
};
