import { createContext, useContext, useState } from 'react';
import * as React from 'react';

import { fetchNav, lazyLoadScripts, loadCSS } from '@/lib/dmm/nav';
import { DmmNavElement } from '@/lib/dmm/nav/type';

interface DmmNavState {
  element?: DmmNavElement;
  isFetched: boolean;
  isLoadedCSS: boolean;
  fetch: (isMobile: boolean) => void;
  loadCSS: () => void;
  lazyLoadScripts: () => void;
}

const DmmNavContext = createContext<DmmNavState>({} as DmmNavState);

export const DmmNavProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<DmmNavState>({} as DmmNavState);

  value.fetch = isMobile => {
    fetchNav(isMobile)
      .then(element => {
        setValue(prevState => ({
          ...prevState,
          element,
          isFetched: true,
        }));
      })
      .catch(() => {
        setValue(prevState => ({
          ...prevState,
          isFetched: true,
        }));
      });
  };

  value.loadCSS = () => {
    if (value.element!.style === undefined) {
      return;
    }
    loadCSS(value.element!.style).then(() => {
      setValue(prevState => ({
        ...prevState,
        isLoadedCSS: true,
      }));
    });
  };

  value.lazyLoadScripts = () => {
    lazyLoadScripts(value.element!.scripts!);
  };

  return (
    <DmmNavContext.Provider value={value}>{children}</DmmNavContext.Provider>
  );
};

export const useDmmNavContext = () => useContext(DmmNavContext);
